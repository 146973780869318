import React, { useEffect, useState } from 'react';
import { db, auth } from './firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';

function TimeCardPage() {
    const [groupedTimecards, setGroupedTimecards] = useState([]);

    useEffect(() => {
        const fetchAndCompareTimecards = async () => {
            if (auth.currentUser) {
                const userEmail = auth.currentUser.email;

                try {
                    // Fetch schedule documents
                    const scheduleQuery = query(collection(db, 'schedule'), where('email', '==', userEmail), where('type', '==', 'Start'));
                    const scheduleSnapshot = await getDocs(scheduleQuery);
                    const scheduleEntries = scheduleSnapshot.docs.map(doc => {
                        const data = doc.data();
                        const timestamp = new Date(data.timestamp);

                        return {
                            id: doc.id,
                            ...data,
                            timestamp,
                            formattedDate: timestamp.toLocaleDateString('en-US', {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            }),
                            daysAgo: Math.floor((new Date() - timestamp) / (1000 * 60 * 60 * 24)), // Calculate days ago
                        };
                    });

                    // Fetch timecard documents
                    const timecardsQuery = query(collection(db, 'timecards'), where('email', '==', userEmail));
                    const timecardsSnapshot = await getDocs(timecardsQuery);
                    const timecards = timecardsSnapshot.docs.map(doc => {
                        const data = doc.data();
                        const timestamp = new Date(data.timestamp);

                        return {
                            id: doc.id,
                            ...data,
                            timestamp,
                            formattedDate: timestamp.toLocaleDateString('en-US', {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            }),
                            daysAgo: Math.floor((new Date() - timestamp) / (1000 * 60 * 60 * 24)), // Calculate days ago
                        };
                    });

                    // Group timecards by formattedDate
                    const grouped = {};
                    timecards.forEach(card => {
                        const dateKey = card.formattedDate; // Group by formatted date
                        (grouped[dateKey] = grouped[dateKey] || []).push(card);
                    });

                    // Sort timecards within each day
                    Object.keys(grouped).forEach(date => {
                        grouped[date].sort((a, b) => a.timestamp - b.timestamp);
                    });

                    // Compare schedule and timecards
                    const groupedArray = Object.entries(grouped).map(([date, entries]) => {
                        const scheduleEntry = scheduleEntries.find(entry => entry.formattedDate === date);

                        const isTardy = scheduleEntry
                            ? entries[0]?.timestamp > scheduleEntry.timestamp // Compare first IN time with schedule Start time
                            : false;

                        return {
                            date,
                            daysAgo: entries[0]?.daysAgo ?? null, // Get daysAgo from the first entry
                            entries: entries.map(entry => ({
                                ...entry,
                                isTardy: scheduleEntry && entry === entries[0] && isTardy // Mark only the first IN as tardy
                            })),
                            scheduleTime: scheduleEntry ? scheduleEntry.timestamp : null
                        };
                    });

                    // Sort by most recent date first
                    groupedArray.sort((a, b) => new Date(b.entries[0].timestamp) - new Date(a.entries[0].timestamp));

                    setGroupedTimecards(groupedArray);
                } catch (error) {
                    console.error("Failed to fetch and compare timecards:", error);
                }
            }
        };

        fetchAndCompareTimecards();
    }, []);

    return (
        <div className="container mt-3">
            <h2>Your Time Cards</h2>
            {groupedTimecards.map(({ date, entries, scheduleTime, daysAgo }) => (
                <div key={date} className="list-group mb-3">
                    <div className="list-group-item list-group-item-action" aria-current="true">
                        <div className="w-100">
                            <h5 className="mb-1">{date}</h5>
                            <p className="mb-1">
                                <small>{daysAgo === 0 ? 'Today' : `${Math.abs(daysAgo)} days ${daysAgo > 0 ? 'ago' : 'from now'}`}</small>
                            </p>
                            {scheduleTime ? (
                                <p className="mb-1">
                                    <small>Scheduled Start: {new Date(scheduleTime).toLocaleTimeString('en-US', {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}</small>
                                </p>
                            ) : (
                                <p className="mb-1">
                                    <small>No schedule for this day</small>
                                </p>
                            )}
                        </div>
                    </div>
                    {entries.map(card => (
                        <div
                            key={card.id}
                            className="list-group-item"
                            style={{
                                color: card.isTardy ? 'red' : 'inherit',
                            }}
                        >
                            <p className="mb-1">{card.type} at {card.timestamp.toLocaleTimeString('en-US', {
                                hour: '2-digit',
                                minute: '2-digit',
                            })}</p>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default TimeCardPage;
