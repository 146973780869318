import React, { useEffect, useState } from 'react';
import { db } from './firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';

function InstructorDashboard() {
    const [documents, setDocuments] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [filteredDocuments, setFilteredDocuments] = useState([]);
    const [sortDirection, setSortDirection] = useState('asc');  // Toggle between 'asc' and 'desc'

    useEffect(() => {
        const fetchDocuments = async () => {
            const querySnapshot = await getDocs(collection(db, 'attendance'));
            const loadedDocuments = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setDocuments(loadedDocuments);
            setFilteredDocuments(loadedDocuments);
        };
        fetchDocuments();
    }, []);

    const handleSort = (column) => {
        const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        setSortDirection(newDirection);
        const sortedDocuments = [...documents].sort((a, b) => {
            const first = a[column] ?? ''; // Default to empty string if undefined
            const second = b[column] ?? '';
            return (first.localeCompare(second) * (newDirection === 'asc' ? 1 : -1));
        });
        setDocuments(sortedDocuments);
        setFilteredDocuments(sortedDocuments);
    };

    const handleSearchChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);

        if (term.length > 0) {
            const lowerCaseTerm = term.toLowerCase();
            const matchedSuggestions = documents
                .filter(doc => doc.name && typeof doc.name === 'string')
                .map(doc => doc.name)
                .filter(name => name.toLowerCase().includes(lowerCaseTerm))
                .filter((name, index, array) => array.indexOf(name) === index);
            setSuggestions(matchedSuggestions);
            setFilteredDocuments(documents.filter(doc => doc.name && typeof doc.name === 'string' && doc.name.toLowerCase().includes(lowerCaseTerm)));
        } else {
            setSuggestions([]);
            setFilteredDocuments(documents);
        }
    };

    const handleSuggestionClick = (name) => {
        setSearchTerm(name);
        setSuggestions([]);
        setFilteredDocuments(documents.filter(doc => doc.name && doc.name.toLowerCase() === name.toLowerCase()));
    };

    return (
        <div className="container mt-3">
            <h2>All Attendance Violations</h2>
            <div className="mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Student Name"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                {suggestions.length > 0 && (
                    <ul className="list-group mt-2">
                        {suggestions.map((suggestion, index) => (
                            <li
                                key={index}
                                className="list-group-item list-group-item-action"
                                onClick={() => handleSuggestionClick(suggestion)}
                            >
                                {suggestion}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => handleSort('name')}>Name</th>
                        <th onClick={() => handleSort('date')}>Date</th>
                        <th onClick={() => handleSort('type')}>Type</th>
                        <th onClick={() => handleSort('status')}>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredDocuments.map((doc, index) => (
                        <tr key={index}>
                            <td>{doc.name}</td>
                            <td>{doc.date}</td>
                            <td>{doc.type}</td>
                            <td>{doc.status}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default InstructorDashboard;
