import React, { useEffect, useState } from 'react';
import { db, auth } from './firebaseConfig';
import { collection, query, where, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';

function Notifications({ isAdmin }) {
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        const fetchDocuments = async () => {
            if (auth.currentUser) {
                const userEmail = auth.currentUser.email;
                const q = query(collection(db, 'attendance'), where('email', '==', userEmail));
                const querySnapshot = await getDocs(q);
                setDocuments(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id, isEditing: false })));
            }
        };

        fetchDocuments();
    }, []);

    const handleEditChange = (id, event) => {
        const { name, value } = event.target;
        setDocuments(documents.map(doc => doc.id === id ? { ...doc, [name]: value } : doc));
    };

    const toggleEdit = (id) => {
        setDocuments(documents.map(doc => doc.id === id ? { ...doc, isEditing: !doc.isEditing } : doc));
    };

    const saveChanges = async (id) => {
        const docToUpdate = documents.find(doc => doc.id === id);
        const { notified, date, type, status } = docToUpdate;
        try {
            await updateDoc(doc(db, 'attendance', id), { notified, date, type, status });
            toggleEdit(id);
            alert('Document updated successfully');
        } catch (error) {
            console.error('Error updating document:', error);
            alert('Failed to update document');
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteDoc(doc(db, 'attendance', id));
            setDocuments(documents.filter(doc => doc.id !== id));
            alert('Document deleted successfully');
        } catch (error) {
            console.error('Error deleting document:', error);
            alert('Failed to delete document');
        }
    };

    return (
        <div className="container mt-3">
            <h2>Attendance Violations</h2>
            <table className="table">
                <thead>
                    <tr>
                        <th>Notified On</th>
                        <th>Type</th>
                        <th>Date</th>
                        <th>Status</th>
                        {isAdmin && <th>Actions</th>}
                    </tr>
                </thead>
                <tbody>
                    {documents.map((document) => (
                        <tr key={document.id}>
                            {document.isEditing ? (
                                <>
                                    <td>
                                        <input type="date" className="form-control" name="notified" value={document.notified} onChange={(e) => handleEditChange(document.id, e)} />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" name="type" value={document.date} onChange={(e) => handleEditChange(document.id, e)} />
                                    </td>
                                    <td>
                                        <input type="date" className="form-control" name="date" value={document.type} onChange={(e) => handleEditChange(document.id, e)} />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" name="status" value={document.status} onChange={(e) => handleEditChange(document.id, e)} />
                                    </td>
                                    <td>
                                        <button className="btn btn-success me-2" onClick={() => saveChanges(document.id)}>Save</button>
                                        <button className="btn btn-secondary" onClick={() => toggleEdit(document.id)}>Cancel</button>
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td>{document.notified}</td>
                                    <td>{document.type}</td>
                                    <td>{document.date}</td>
                                    <td>{document.status}</td>
                                    {isAdmin && (
                                        <td>
                                            <button className="btn btn-primary me-2" onClick={() => toggleEdit(document.id)}>Edit</button>
                                            <button className="btn btn-danger" onClick={() => handleDelete(document.id)}>Delete</button>
                                        </td>
                                    )}
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Notifications;
