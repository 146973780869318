import React, { useState, useEffect } from 'react';
import { db } from './firebaseConfig'; // Ensure your Firebase config is correctly imported
import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import { format, parseISO } from 'date-fns'; // Import parseISO to handle ISO string dates

function ClockedInUsers() {
    const [clockedInUsers, setClockedInUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchTimecards = async () => {
            setLoading(true);
            try {
                const q = query(collection(db, "timecards"), orderBy("timestamp", "asc"));
                const snapshot = await getDocs(q);
                const timecards = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    // Use parseISO to convert the string timestamp to a Date object, then format it
                    formattedTimestamp: doc.data().timestamp ? format(parseISO(doc.data().timestamp), "yyyy-MM-dd HH:mm:ss") : 'Unknown Time'
                }));

                console.log("Snapshot Data:", timecards); // Log fetched data

                const filteredCards = filterClockedInUsers(timecards);
                console.log("Filtered Cards:", filteredCards); // Log processed data

                setClockedInUsers(filteredCards);
            } catch (error) {
                console.error("Error fetching clocked in users:", error);
            }
            setLoading(false);
        };

        fetchTimecards();
    }, []);

    function filterClockedInUsers(cards) {
        const userStatus = new Map(); // To track the latest status by user
    
        // Sorting records to ensure they are processed in chronological order
        cards.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
    
        cards.forEach(card => {
            if (card.status === 'IN') {
                userStatus.set(card.userId, card); // Mark user as clocked in
            } else if (card.status === 'OUT') {
                if (userStatus.get(card.userId) && userStatus.get(card.userId).timestamp < card.timestamp) {
                    userStatus.delete(card.userId); // Mark user as clocked out
                }
            }
        });
    
        // Return an array of users who are still marked as 'IN'
        return Array.from(userStatus.values());
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (clockedInUsers.length === 0) {
        return <div>No users currently clocked in.</div>;
    }

    return (
        <div className="container mt-3">
            <h2>Clocked In Users</h2>
            <ul>
                {clockedInUsers.map(user => (
                    <li key={user.id}>
                        {user.name || 'Unknown User'} clocked in at {user.formattedTimestamp}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default ClockedInUsers;
