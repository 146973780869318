import './App.css';
import React, { useEffect, useState } from 'react';
import Notifications from "./Notifications";
import AdminPanel from './AdminPanel';
import DeleteAll from './DeleteAll';
import { auth } from './firebaseConfig';
import { signOut } from 'firebase/auth';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Login from './Login';
import logo from './logo.png';  // Assuming you have a logo.png in your src folder
import InstructorDashboard from './InstructorDashboard';
import UsersPage from './UsersPage'; 
import Home from './Home'; // Make sure the path is correct
import Schedule from './Schedule';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Footer from './components/Footer'; // Adjust the path based on your directory structure
import axios from 'axios';  // Move axios to the top as an import
import AddRecordForm from './admin/AddRecordForm';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TimeCardPage from './TimeCardPage'; // The component we will create for the /timecard route
import AdminTimeCardPage from './AdminTimeCardPage'; // The component we will create for the /timecard route
import TimeCardUpload from "./TimeCardUpload"
import ScheduleUpload from "./ScheduleUpload"
import UsersUpload from "./UsersUpload"
import NotificationsUpload from "./NotificationsUpload";
import ClockedInUsers from "./ClockedInUsers";

const url = 'https://axialprogress.com';

function App() {
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isInstructor, setIsInstructor] = useState(false);

    useEffect(() => {
        // Axios request to fetch data on component load
        axios.get(url)
            .then(response => console.log(response.data))
            .catch(error => console.error('Error:', error));
    }, []);  // Empty dependency array to run only once on load

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
            setIsAdmin(currentUser && ['mjcallos@gmail.com', 'mcallos@gurnick.edu'].includes(currentUser.email));
            
            // Set instructor based on a predefined list of emails
            setIsInstructor(currentUser && ['cborenstadt@gurnick.edu', 'krodrigues@gurnick.edu', 'thairston@gurnick.edu', 'erosebrock@gurnick.edu', 'mjcallos@gmail.com', 'mcallos@gurnick.edu'].includes(currentUser.email));
        });
        
        return unsubscribe;  // Cleanup function
    }, []);
    
    const handleLogout = () => {
        signOut(auth).then(() => {
            toast.success("Logged out successfully"); // Show success message as a toast
        }).catch((error) => {
            toast.error("Logout failed"); // Show error message as a toast
        });
    };

    return (
        <Router>
            <div className="App container mt-4">
            <img src={logo} alt="Logo" className="img-fluid" style={{ maxWidth: '100px' }} />
            {user ? (
                    <>
                        <h1>Axial Progress</h1>
                        <div className="d-flex flex-column min-vh-100">
                        <nav className="navbar navbar-expand-lg navbar-light bg-light mb-3">
                            <Link className="navbar-brand" to="/">
                                <i className="fas fa-home"></i> {/* Home icon*/}
                            </Link>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav mr-auto">
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/notifications">Notifications</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/schedule">Schedule</Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link className="nav-link" to="/timecard">Time Card</Link>
                                    </li>
                                    {isInstructor && (
                                        <li className='nav-item'>
                                            <Link className="nav-link" to="/instructor-dashboard">Instructor Dashboard</Link>
                                        </li>
                                    )}
                                    {isAdmin && (
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="/admin" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Admin
                                            </a>
                                            <ul className="dropdown-menu">
                                                <li><a className="dropdown-item" href="/alltimecards">All Time Cards</a></li>
                                                <li><a className="dropdown-item" href="/admin">Admin</a></li>
                                                <li><a className="dropdown-item" href="/users">Users</a></li>
                                                <li><a className="dropdown-item" href="/add-record">Add Violation</a></li>
                                                <li><a className="dropdown-item" href="/notifications-upload">Upload Notifications</a></li>
                                                <li><a className="dropdown-item" href="/schedule-upload">Upload Schedule</a></li>
                                                <li><a className="dropdown-item" href="/timecard-upload">Upload Time Card</a></li>
                                                <li><a className="dropdown-item" href="/users-upload">Upload Users</a></li>
                                                <li><a className="dropdown-item" href="/delete-all">Delete All Violations</a></li>
                                            </ul>
                                        </li>
                                    )}
                                </ul>
                                <div>
                                    <button className="btn btn-outline-danger" onClick={handleLogout}>Log Out</button>
                                    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                                </div>
                            </div>
                        </nav>
                        <Routes>
                            <Route path="/clockedinusers" element={isInstructor ? <ClockedInUsers /> : <p>Access Denied</p>} />
                            <Route path="/instructor-dashboard" element={isInstructor ? <InstructorDashboard /> : <p>Access Denied</p>} />
                            <Route path="/notifications" element={<Notifications isAdmin={isAdmin} />} />
                            <Route path="/admin" element={isAdmin ? <AdminPanel /> : <p>Access denied</p>} />
                            <Route path="/add-record" element={isAdmin ? <AddRecordForm /> : <p>Access denied</p>} />
                            <Route path="/delete-all" element={isAdmin ? <DeleteAll /> : <p>Access denied</p>} />
                            <Route path="/users" element={isAdmin ? <UsersPage /> : <p>Access denied</p>} />
                            <Route path="/" element={<Home />} />
                            <Route path="/alltimecards" element={isAdmin ? <AdminTimeCardPage /> : <p>Access denied</p>} />
                            <Route path="/timecard" element={<TimeCardPage />} />
                            <Route path="/Schedule" element={<Schedule />} />
                            <Route path="/notifications-upload" element={<NotificationsUpload />} />
                            <Route path="/timecard-upload" element={<TimeCardUpload />} />
                            <Route path="/schedule-upload" element={<ScheduleUpload />} />
                            <Route path="/users-upload" element={<UsersUpload />} />
                        </Routes>
                        <Footer />
                        </div>
                    </>
                ) : (
                    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
                        <Login />
                    </div>
                )}
            </div>
        </Router>
    );
}

export default App;
