import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { format } from 'date-fns';

function UsersPage() {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const db = getFirestore();
        const fetchUsers = async () => {
            const usersCollection = collection(db, 'users');
            const snapshot = await getDocs(usersCollection);
            const usersList = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                cohort: doc.data().cohort || 'Not Assigned'  // Defaulting cohort if undefined
            }));
            setUsers(usersList);
            setFilteredUsers(usersList);
            setLoading(false);
        };

        fetchUsers();
    }, []);

    const handleCohortChange = async (userId, newCohort) => {
        const db = getFirestore();
        const userRef = doc(db, 'users', userId);
        await updateDoc(userRef, {
            cohort: newCohort
        }).then(() => {
            const updatedUsers = users.map(user => {
                if (user.id === userId) {
                    return { ...user, cohort: newCohort };
                }
                return user;
            });
            setUsers(updatedUsers);
            setFilteredUsers(updatedUsers);
        }).catch(error => {
            console.error('Error updating user:', error);
        });
    };

    const handleProfileClick = (user) => {
        // Select only the fields you want to display in the modal
        const userDetailsToShow = {
            name: user.name,
            email: user.email,
            lastLogin: user.lastLogin
        };
    
        setCurrentUser(userDetailsToShow);
        setShowModal(true);
    };
    
    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        const filtered = users.filter(user => user.name.toLowerCase().includes(value) || user.cohort.toLowerCase().includes(value));
        setFilteredUsers(filtered);
    };

    const formatDate = (timestamp) => {
        try {
            // Check if the timestamp is not null or undefined
            if (!timestamp) throw new Error('No timestamp provided');
    
            // Convert Firestore timestamp to JavaScript Date object
            const date = timestamp.toDate();  // Assuming 'timestamp' is a Firestore timestamp object
    
            // Format the date using date-fns
            return format(date, 'PPP p');
        } catch (error) {
            console.error(error);
            return "Invalid date";  // Provide a fallback message or handling
        }
    };

    if (loading) {
        return <div>Loading users...</div>;
    }

    return (
        <div className="container mt-3">
            <h1>Users List</h1>
            <Form.Floating className="mb-3">
                <Form.Control
                    type="search"
                    placeholder="Search users..."
                    onChange={handleSearch}
                    className="mb-3"
                />
                <label htmlFor="floatingInputCustom">Search Users</label>
            </Form.Floating>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Profile Picture</th>
                        <th>Name</th>
                        <th>Cohort</th>
                        <th>Details</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredUsers.map(user => (
                        <tr key={user.id}>
                            <td>
                                <img src={user.profilePicture || "default_profile.png"} alt="profile" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                            </td>
                            <td>{user.name}</td>
                            <td>
                                <Dropdown>
                                    <Dropdown.Toggle variant={user.cohort === 'Not Assigned' ? 'danger' : 'secondary'} id="dropdown-basic">
                                        {user.cohort}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => handleCohortChange(user.id, "Instructor")}>Instructor</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleCohortChange(user.id, "Admin")}>Admin</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleCohortChange(user.id, "ASRT9")}>ASRT 9</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleCohortChange(user.id, "ASRT10A")}>ASRT 10A</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleCohortChange(user.id, "ASRT10B")}>ASRT 10B</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleCohortChange(user.id, "Not Assigned")}>Not Assigned</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                            <td onClick={() => handleProfileClick(user)}>
                                <AiOutlineInfoCircle style={{ cursor: 'pointer' }} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>User Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {currentUser && Object.entries(currentUser).map(([key, value]) => (
                        <p key={key}><strong>{key}:</strong> {key === 'lastLogin' ? formatDate(value) : value.toString()}</p>
                        ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default UsersPage;
