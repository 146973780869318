import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';

function AddRecordForm() {
    const today = new Date().toISOString().split('T')[0]; // Get today's date in 'YYYY-MM-DD' format
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        date: today, // Default date to today
        type: '',
        status: '',
    });
    const [isAdded, setIsAdded] = useState(false); // Declare state for tracking addition status

    useEffect(() => {
        const fetchDocuments = async () => {
            // Assuming you may use the documents for something
        };
        fetchDocuments();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setIsAdded(false); // Reset isAdded when form is edited
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsAdded(false); // Reset on new submission attempt
        try {
            await addDoc(collection(db, 'attendance'), formData);
            setIsAdded(true); // Set to true on successful addition
            setTimeout(() => setIsAdded(false), 3000); // Reset after 3 seconds
        } catch (error) {
            console.error('Error adding document:', error);
            alert('Failed to add record.'); // Keep the alert for errors or handle differently
        }
    };

    return (
        <div className="container mt-3">
            <h2>Add Record</h2>
            <form onSubmit={handleSubmit} className="mb-3">
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" id="name" name="name" placeholder="Name" value={formData.name} onChange={handleInputChange} />
                    <label htmlFor="name">Name</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="email" className="form-control" id="email" name="email" placeholder="Email" value={formData.email} onChange={handleInputChange} />
                    <label htmlFor="email">Email</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="date" className="form-control" id="date" name="date" value={formData.date} onChange={handleInputChange} />
                    <label htmlFor="date">Date</label>
                </div>
                <div className="form-floating mb-3">
                    <select id="type" name="type" className="form-control" value={formData.type} onChange={handleInputChange}>
                        <option value="">Select Type</option>
                        <option value="Tardy">Tardy</option>
                        <option value="Time Correction">Time Correction</option>
                        <option value="Excused Absence">Excused Absence</option>
                        <option value="Unexcused Absence">Unexcused Absence</option>
                    </select>
                    <label htmlFor="type">Type</label>
                </div>
                <div className="form-floating mb-3">
                    <select id="status" name="status" className="form-control" value={formData.status} onChange={handleInputChange}>
                        <option value="">Select Status</option>
                        <option value="Complete">Complete</option>
                        <option value="Make Up Needed">Make Up Needed</option>
                    </select>
                    <label htmlFor="status">Status</label>
                </div>
                <button type="submit" className={`btn ${isAdded ? 'btn-success' : 'btn-primary'}`}>
                    {isAdded ? 'Record Added' : 'Add Record'}
                </button>
            </form>
        </div>
    );
}

export default AddRecordForm; // Correct placement for export
