import React, { useEffect, useState, useCallback } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from './firebaseConfig';

function Home() {
  const [userName, setUserName] = useState('');
  const [tardyCount, setTardyCount] = useState(0);
  const [unexcusedAbsenceCount, setUnexcusedAbsenceCount] = useState(0);
  const [makeUpDates, setMakeUpDates] = useState([]);

  const fetchData = useCallback(async (email) => {
    const q = query(collection(db, 'attendance'), where('email', '==', email));
    const querySnapshot = await getDocs(q);
    const docs = querySnapshot.docs.map(doc => doc.data());
    calculateTotals(docs);
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        setUserName(user.displayName || "User");
        fetchData(user.email);
      } else {
        setUserName('');
        setTardyCount(0);
        setUnexcusedAbsenceCount(0);
        setMakeUpDates([]);
      }
    });

    return () => unsubscribe();
  }, [fetchData]);

  const calculateTotals = (docs) => {
    let tardyTotal = 0;
    let unexcusedAbsenceTotal = 0;
    const datesRequiringMakeUp = [];

    docs.forEach(doc => {
      if (doc.type === 'Tardy') {
        tardyTotal += 1;
      } else if (doc.type === 'Unexcused Absence') {
        unexcusedAbsenceTotal += 1;
      }

      if (doc.status === 'Make Up Needed' && doc.date) {
        datesRequiringMakeUp.push(doc.date);
      }
    });

    setTardyCount(tardyTotal);
    setUnexcusedAbsenceCount(unexcusedAbsenceTotal);
    setMakeUpDates(datesRequiringMakeUp);
  };

  return (
    <div>
      {makeUpDates.length > 0 && (
        <div className="alert alert-danger alert-dismissible fade show">
          WARNING! Please complete a make-up request form for: {makeUpDates.join(', ')}
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      )}
      <h2>Welcome{userName ? `, ${userName}` : ''}</h2>
      <div>
      <div className="alert alert-warning alert-dismissible fade show">
          <h5>You have {tardyCount} {tardyCount === 1 ? "Tardy" : "Tardies"} and {unexcusedAbsenceCount} {unexcusedAbsenceCount === 1 ? "Unexcused Absence" : "Unexcused Absences"}</h5>
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
      <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th colSpan="2" className="text-center bg-dark text-white">
              Tardies
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1 tardy</td>
            <td>Verbal Warning</td>
          </tr>
          <tr>
            <td>2 tardies</td>
            <td>Written Warning</td>
          </tr>
          <tr>
            <td>3 tardies</td>
            <td>Written Warning, and a 5% deduction in the final grade</td>
          </tr>
          <tr>
            <td>4 or more</td>
            <td>Written Warning, and a 10% deduction in the final grade</td>
          </tr>
          </tbody>
        </table>
    </div>
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th colSpan="2" className="text-center bg-dark text-white">
            Unexcused Absences
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1 day</td>
            <td>Verbal Warning</td>
          </tr>
          <tr>
            <td>2 days</td>
            <td>Written Warning and 5% deduction in the final grade</td>
          </tr>
          <tr>
            <td>3 days</td>
            <td>Written Warning, Disciplinary Probation, and a 10% deduction in the final grade</td>
          </tr>
          <tr>
            <td>4 or more</td>
            <td>Dismissal from the program</td>
          </tr>
          </tbody>
        </table>
    </div>      
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th colSpan="2" className="text-center bg-dark text-white">
            Time Exceptions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>More than 5</td>
            <td>3% deduction in final grade</td>
          </tr>
          <tr>
            <td>More than 10</td>
            <td>10% deduction in final grade</td>
          </tr>
          </tbody>
        </table>
    </div>      
  </div>
    </div>
  );
}

export default Home;
