// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from 'firebase/functions';  // Added for cloud functions

const firebaseConfig = {
    apiKey: "AIzaSyBV0XXwz2eNxuFB5GBrh-clWlFKKrXNoqc",
    authDomain: "rt-attendance-tracker-c338c.firebaseapp.com",
    projectId: "rt-attendance-tracker-c338c",
    storageBucket: "rt-attendance-tracker-c338c.appspot.com",
    messagingSenderId: "401978920356",
    appId: "1:401978920356:web:73894d6c074d31ba5d4531"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);  // Exporting initialized functions
