import React, { useState, useEffect } from 'react';
import { db } from './firebaseConfig';
import { collection, addDoc, getDocs, deleteDoc, updateDoc, doc, writeBatch } from 'firebase/firestore';
import Papa from 'papaparse';

function AdminPanel() {
    const today = new Date().toISOString().split('T')[0]; // Get today's date in 'YYYY-MM-DD' format
    const [formData, setFormData] = useState({
        email: '',
        date: today, // Default date to today
        type: '',
        status: '',
    });
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        const fetchDocuments = async () => {
            const querySnapshot = await getDocs(collection(db, 'attendance'));
            setDocuments(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id, isEditing: false })));
        };
        fetchDocuments();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await addDoc(collection(db, 'attendance'), formData);
            alert('Record added successfully');
        } catch (error) {
            console.error('Error adding document:', error);
            alert('Failed to add record.');
        }
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: async (results) => {
                const rows = results.data;
                try {
                    for (const row of rows) {
                        await addDoc(collection(db, 'attendance'), row);
                    }
                    alert('CSV data uploaded successfully');
                } catch (error) {
                    console.error('Error uploading data:', error);
                    alert('Failed to upload CSV data');
                }
            },
        });
    };

    const toggleEdit = (id) => {
        setDocuments(documents.map(doc => doc.id === id ? { ...doc, isEditing: !doc.isEditing } : doc));
    };

    const handleEditChange = (id, event) => {
        const { name, value } = event.target;
        setDocuments(documents.map(doc => doc.id === id ? { ...doc, [name]: value } : doc));
    };

    const saveChanges = async (id) => {
        const docToUpdate = documents.find(doc => doc.id === id);
        const { email, date, type, status } = docToUpdate;
        try {
            await updateDoc(doc(db, 'attendance', id), { email, date, type, status });
            toggleEdit(id);
            alert('Document updated successfully');
        } catch (error) {
            console.error('Error updating document:', error);
            alert('Failed to update document');
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteDoc(doc(db, 'attendance', id));
            setDocuments(documents.filter(doc => doc.id !== id));
            alert('Document deleted successfully');
        } catch (error) {
            console.error('Error deleting document:', error);
            alert('Failed to delete document');
        }
    };

    const deleteAllRecords = async () => {
        const batch = writeBatch(db);
        documents.forEach((document) => {
            batch.delete(doc(db, 'attendance', document.id));
        });

        try {
            await batch.commit();
            setDocuments([]); // Clear the documents state
            alert('All records have been deleted.');
        } catch (error) {
            console.error('Error deleting all documents:', error);
            alert('Failed to delete all documents');
        }
    };

    return (
        <div className="container mt-3">
            <h2>Admin Panel</h2>
            <form onSubmit={handleSubmit} className="mb-3">
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">Name</label>
                    <input type="name" className="form-control" id="name" name="name" placeholder="Name" onChange={handleInputChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input type="email" className="form-control" id="email" name="email" placeholder="Email" onChange={handleInputChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="date" className="form-label">Date</label>
                    <input
                        type="date"
                        className="form-control"
                        id="date"
                        name="date"
                        value={formData.date} // Set default date to today's date
                        onChange={handleInputChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="type" className="form-label">Type</label>
                    <select id="type" name="type" className="form-control" onChange={handleInputChange}>
                        <option value="">Select Type</option>
                        <option value="Tardy">Tardy</option>
                        <option value="Time Correction">Time Correction</option>
                        <option value="Excused Absence">Excused Absence</option>
                        <option value="Unexcused Absence">Unexcused Absence</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="status" className="form-label">Status</label>
                    <select id="status" name="status" className="form-control" onChange={handleInputChange}>
                        <option value="">Select Type</option>
                        <option value="Complete">Complete</option>
                        <option value="Make Up Needed">Make Up Needed</option>
                    </select>
                </div>
                <button type="submit" className="btn btn-primary">Add Record</button>
            </form>
            <button className="btn btn-danger mb-3" onClick={deleteAllRecords}>Delete All Records</button>
            <div>
                <label htmlFor="csvUpload" className="form-label">Upload CSV:</label>
                <input type="file" className="form-control" id="csvUpload" accept=".csv" onChange={handleFileUpload} />
            </div>
            {documents.map((document) => (
                <div key={document.id} className="card mb-2">
                    {document.isEditing ? (
                        <div className="card-body">
                            <input type="text" className="form-control mb-2" name="name" value={document.name} onChange={(e) => handleEditChange(document.id, e)} />
                            <input type="text" className="form-control mb-2" name="email" value={document.email} onChange={(e) => handleEditChange(document.id, e)} />
                            <input type="date" className="form-control mb-2" name="date" value={document.date} onChange={(e) => handleEditChange(document.id, e)} />
                            <input type="text" className="form-control mb-2" name="type" value={document.type} onChange={(e) => handleEditChange(document.id, e)} />
                            <input type="text" className="form-control mb-2" name="status" value={document.status} onChange={(e) => handleEditChange(document.id, e)} />
                            <button className="btn btn-success me-2" onClick={() => saveChanges(document.id)}>Save</button>
                            <button className="btn btn-secondary" onClick={() => toggleEdit(document.id)}>Cancel</button>
                        </div>
                    ) : (
                        <div className="card-body">
                            <p>Name: {document.name}</p>
                            <p>Email: {document.email}</p>                            
                            <p>Date: {document.date}</p>
                            <p>Type: {document.type}</p>
                            <p>Status: {document.status}</p>
                            <button className="btn btn-primary me-2" onClick={() => toggleEdit(document.id)}>Edit</button>
                            <button className="btn btn-danger" onClick={() => handleDelete(document.id)}>Delete</button>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default AdminPanel;
