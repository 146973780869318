import React, { useState } from 'react';
import Papa from 'papaparse';
import { db } from './firebaseConfig';
import { collection, query, where, getDocs, addDoc } from 'firebase/firestore';
import 'bootstrap/dist/css/bootstrap.min.css';

function TimeCardUpload() {
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [progress, setProgress] = useState(0);  // State to keep track of progress
    const [message, setMessage] = useState('');

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        setMessage('');
        setIsUploaded(false);
        setProgress(0);  // Reset progress on new file selection
    };

    const checkDuplicateAndAdd = async (item, index, total) => {
        const { email, Timestamp, Type } = item;
        console.log("Processing item:", item);

        if (!email || !Timestamp) {
            console.log("Invalid data found", item);
            return true;  // Assume true to skip adding it
        }

        const q = query(collection(db, 'timecards'), where("email", "==", email), where("timestamp", "==", Timestamp));
        const docs = await getDocs(q);

        if (docs.empty) {
            console.log("No duplicates found, adding new document: ", item);
            await addDoc(collection(db, 'timecards'), {
                name: item.Name,
                email: email,
                timestamp: Timestamp,
                type: Type
            });
            return false; // No duplicate, added new doc
        } else {
            console.log("Duplicate found, skipping: ", item);
        }

        // Update progress
        setProgress((prevProgress) => {
            const progressIncrement = 100 * (index + 1) / total;
            return Math.min(progressIncrement, 100);  // Ensure progress does not exceed 100%
        });

        return true; // Duplicate found, did not add
    };

    const handleUpload = () => {
        if (file) {
            setUploading(true);
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: async function(results) {
                    let duplicates = 0;
                    for (let i = 0; i < results.data.length; i++) {
                        const item = results.data[i];
                        const isDuplicate = await checkDuplicateAndAdd(item, i, results.data.length);
                        if (isDuplicate) {
                            duplicates++;
                        }
                    }
                    setMessage(`${results.data.length - duplicates} new documents added, ${duplicates} duplicates skipped.`);
                    setIsUploaded(true);
                    setUploading(false);
                    setFile(null);  // Clear file input after processing
                }
            });
        } else {
            setMessage('Please select a file to upload.');
        }
    };

    return (
        <div>
            <h1>Upload Timecard CSV</h1>
            <label htmlFor="formFile" className="form-label">Choose CSV File</label>
            <input 
                type="file" 
                accept=".csv" 
                className="form-control" 
                id="formFile" 
                onChange={handleFileChange} 
                disabled={uploading}
            />
            <button
                type="submit"
                className={`btn ${isUploaded ? 'btn-success' : 'btn-primary'}`}
                onClick={handleUpload}
                disabled={uploading || !file}
            >
                {uploading ? (
                    <>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Uploading...
                    </>
                ) : isUploaded ? 'Upload Successful' : 'Upload'}
            </button>
            {uploading && (
                <div className="progress">
                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${progress}%` }}></div>
                </div>
            )}
            {message && <p>{message}</p>}
        </div>
    );
}

export default TimeCardUpload;
