import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, db } from "./firebaseConfig";
import { doc, setDoc, getDoc } from "firebase/firestore";  // Ensure these are imported

const provider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
    try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;

        // Check if this is a new user or a returning user
        const userRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(userRef);

        if (!docSnap.exists()) {
            // New user: add them to Firestore
            await setDoc(doc(db, "users", user.uid), {
                name: user.displayName,
                email: user.email,
                profilePicture: user.photoURL,
                lastLogin: new Date()  // Store the last login time
            });
        } else {
            // Returning user: update last login time
            await setDoc(doc(db, "users", user.uid), {
                lastLogin: new Date()
            }, { merge: true });
        }

        return user;  // Optionally return user data for further use
    } catch (error) {
        console.error("Error logging in:", error);
        alert("Login failed. Please try again.");
        throw error;  // Rethrow to handle it in the component if needed
    }
};
