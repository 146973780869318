import React, { useState } from 'react';
import { signInWithGoogle } from './auth';  // Adjust the path as necessary
import 'bootstrap/dist/css/bootstrap.min.css';

function Login() {
    const [error, setError] = useState(null);

    const handleGoogleSignIn = async () => {
        try {
            await signInWithGoogle();
        } catch (err) {
            console.error(err);
            setError("Login failed. Please try again.");
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <div className="card p-4 shadow-lg" style={{ maxWidth: '400px' }}>
                <div className="card-body text-center">
                    <h2 className="card-title mb-4">Axial Progress</h2>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <button onClick={handleGoogleSignIn} className="btn btn-primary">
                        Sign in with Google
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Login;
