import React, { useEffect, useState } from 'react';
import { db, auth } from './firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';

function Schedule() {
    const [groupedSchedule, setGroupedSchedule] = useState([]);
    const [showPast, setShowPast] = useState(false); // State to toggle past schedules

    useEffect(() => {
        const fetchSchedule = async () => {
            if (auth.currentUser) {
                const userEmail = auth.currentUser.email;
                const q = query(collection(db, 'schedule'), where('email', '==', userEmail));
                try {
                    const querySnapshot = await getDocs(q);
                    const schedule = querySnapshot.docs.map(doc => {
                        const data = doc.data();
                        const timestamp = new Date(data.timestamp);
                        const diffInDays = Math.floor((new Date() - timestamp) / (1000 * 60 * 60 * 24));

                        return {
                            ...data,
                            id: doc.id,
                            formattedDate: timestamp.toLocaleDateString('en-US', {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            }),
                            formattedTime: timestamp.toLocaleTimeString('en-US', {
                                hour: '2-digit',
                                minute: '2-digit'
                            }),
                            timestamp,
                            isPast: diffInDays > 0,
                            daysAgoText: diffInDays === 0
                                ? 'today'
                                : diffInDays > 0
                                    ? `${diffInDays} days ago`
                                    : `in ${Math.abs(diffInDays)} days` // Future dates
                        };
                    });

                    // Group by formattedDate and sort times within each day
                    const grouped = {};
                    schedule.forEach(card => {
                        (grouped[card.formattedDate] = grouped[card.formattedDate] || []).push(card);
                    });

                    // Sort times within each day
                    Object.keys(grouped).forEach(day => {
                        grouped[day].sort((a, b) => a.timestamp - b.timestamp);
                    });

                    // Convert to array
                    const groupedArray = Object.entries(grouped).map(([date, entries]) => ({
                        date,
                        entries,
                        isPast: entries.every(entry => entry.isPast), // Check if all entries are in the past
                        daysAgoText: entries[0].daysAgoText // taking the daysAgoText from the first entry of each group
                    }));

                    setGroupedSchedule(groupedArray);
                } catch (error) {
                    console.error("Failed to fetch schedule:", error);
                }
            }
        };

        fetchSchedule();
    }, []);

    const pastSchedules = groupedSchedule
        .filter(group => group.isPast)
        .sort((a, b) => new Date(b.entries[0].timestamp) - new Date(a.entries[0].timestamp));

    const futureSchedules = groupedSchedule
        .filter(group => !group.isPast)
        .sort((a, b) => new Date(a.entries[0].timestamp) - new Date(b.entries[0].timestamp)); // Chronological order

    return (
        <div className="container mt-3">
            <h2>Your Schedule</h2>

            <div>
                <h3>Today's and Future Schedule</h3>
                {futureSchedules.map(({ date, entries, daysAgoText }) => (
                    <div key={date} className="list-group mb-3">
                        <div
                            className="list-group-item list-group-item-action"
                            aria-current="true"
                        >
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1">{date}</h5>
                                <small>{daysAgoText}</small>
                            </div>
                        </div>
                        {entries.map(card => (
                            <div
                                key={card.id}
                                className="list-group-item"
                            >
                                <p className="mb-1">{card.type} at {card.formattedTime}</p>
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            <div>
                <button
                    type="button"
                    className={`btn btn-primary btn-lg btn-block`}
                    onClick={() => setShowPast(!showPast)}
                >
                    {showPast ? 'Hide Past Schedule' : 'Show Past Schedule'}
                </button>
                {showPast && (
                    <div>
                        {pastSchedules.map(({ date, entries, daysAgoText }) => (
                            <div key={date} className="list-group mb-3">
                                <div
                                    className="list-group-item list-group-item-action"
                                    aria-current="true"
                                    style={{
                                        color: 'grey',
                                        fontStyle: 'italic',
                                    }}
                                >
                                    <div className="d-flex w-100 justify-content-between">
                                        <h5 className="mb-1">{date}</h5>
                                        <small>{daysAgoText}</small>
                                    </div>
                                </div>
                                {entries.map(card => (
                                    <div
                                        key={card.id}
                                        className="list-group-item"
                                        style={{
                                            color: 'grey',
                                            fontStyle: 'italic',
                                        }}
                                    >
                                        <p className="mb-1">{card.type} at {card.formattedTime}</p>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Schedule;
