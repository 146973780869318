import React, { useEffect, useState } from 'react';
import { db } from './firebaseConfig';
import { collection, getDocs, query, where, deleteDoc, doc } from 'firebase/firestore';

function AdminTimeCardPage() {
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [groupedTimecards, setGroupedTimecards] = useState([]);
    const [lateHour, setLateHour] = useState(9);
    const [lateMinute, setLateMinute] = useState(0);
    const [showModal, setShowModal] = useState(false); // Track modal visibility
    const [deleteId, setDeleteId] = useState(null); // Track which ID to delete

    useEffect(() => {
        const fetchUsers = async () => {
            const q = query(collection(db, 'users'));
            try {
                const querySnapshot = await getDocs(q);
                const usersData = querySnapshot.docs.map(doc => ({
                    email: doc.data().email,
                    name: doc.data().name,
                    id: doc.id
                }));
                setUsers(usersData);
            } catch (error) {
                console.error("Failed to fetch users:", error);
            }
        };
        fetchUsers();
    }, []);

    const handleSearchChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);

        if (term.length > 0) {
            const lowerCaseTerm = term.toLowerCase();
            const matchedSuggestions = users
                .filter(user => user.name && user.name.toLowerCase().includes(lowerCaseTerm))
                .map(user => user.name)
                .filter((name, index, array) => array.indexOf(name) === index);
            setSuggestions(matchedSuggestions);
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (name) => {
        setSearchTerm(name);
        setSuggestions([]);
        const user = users.find(user => user.name.toLowerCase() === name.toLowerCase());
        if (user) setSelectedUser(user.email);
    };

    useEffect(() => {
        if (selectedUser) {
            const fetchTimecards = async () => {
                const q = query(collection(db, 'timecards'), where('email', '==', selectedUser));
                try {
                    const querySnapshot = await getDocs(q);
                    const timecards = querySnapshot.docs.map(doc => {
                        const data = doc.data();
                        const timestamp = new Date(data.timestamp);
                        return {
                            ...data,
                            id: doc.id,
                            formattedDate: timestamp.toLocaleDateString('en-US', {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            }),
                            formattedTime: timestamp.toLocaleTimeString('en-US', {
                                hour: '2-digit',
                                minute: '2-digit'
                            }),
                            timestamp,
                            daysAgo: Math.floor((new Date() - timestamp) / (1000 * 60 * 60 * 24))
                        };
                    });

                    const grouped = {};
                    timecards.forEach(card => {
                        (grouped[card.formattedDate] = grouped[card.formattedDate] || []).push(card);
                    });

                    Object.keys(grouped).forEach(day => {
                        grouped[day].sort((a, b) => a.timestamp - b.timestamp);
                    });

                    const groupedArray = Object.entries(grouped).map(([date, entries]) => ({
                        date,
                        entries,
                        daysAgo: entries[0].daysAgo
                    }));
                    groupedArray.sort((a, b) => new Date(b.entries[0].timestamp) - new Date(a.entries[0].timestamp));

                    setGroupedTimecards(groupedArray);
                } catch (error) {
                    console.error("Failed to fetch timecards:", error);
                }
            };

            fetchTimecards();
        }
    }, [selectedUser]);

    function isLate(timestamp) {
        const lateThreshold = new Date(timestamp);
        lateThreshold.setHours(lateHour, lateMinute, 0, 0);
        return timestamp > lateThreshold;
    }

    const handleDeleteClick = (id) => {
        setDeleteId(id);
        setShowModal(true); // Show modal before deleting
    };

    const confirmDelete = async () => {
        try {
            if (deleteId) {
                await deleteDoc(doc(db, 'timecards', deleteId));
                setGroupedTimecards(prev =>
                    prev.map(group => ({
                        ...group,
                        entries: group.entries.filter(entry => entry.id !== deleteId)
                    })).filter(group => group.entries.length > 0)
                );
                setDeleteId(null); // Clear delete ID
            }
        } catch (error) {
            console.error("Failed to delete timecard:", error);
        }
        setShowModal(false); // Hide modal after deleting
    };

    // Function to calculate total time clocked in for the day
    function calculateTotalTime(entries) {
        let totalMilliseconds = 0;
        let lastInTime = null;

        entries.forEach(entry => {
            if (entry.type === 'IN') {
                lastInTime = entry.timestamp;
            } else if (entry.type === 'OUT' && lastInTime) {
                totalMilliseconds += entry.timestamp - lastInTime;
                lastInTime = null;
            }
        });

        const totalMinutes = Math.floor(totalMilliseconds / (1000 * 60));
        const totalHours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        return {
            formatted: `${totalHours}h ${minutes}m`,
            totalMinutes
        };
    }

    return (
        <div className="container mt-3">
            <h2>Time Cards</h2>

            <div className="form-floating mb-3">
                <input
                    type="text"
                    className="form-control"
                    id="userSearch"
                    placeholder="Student Name"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <label htmlFor="userSearch">Search User</label>
                {suggestions.length > 0 && (
                    <ul className="list-group mt-2">
                        {suggestions.map((suggestion, index) => (
                            <li
                                key={index}
                                className="list-group-item list-group-item-action"
                                onClick={() => handleSuggestionClick(suggestion)}
                            >
                                {suggestion}
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            <div className="mb-3">
                <label htmlFor="lateHour">Set Late Threshold Time:</label>
                <input
                    type="number"
                    id="lateHour"
                    value={lateHour}
                    onChange={(e) => setLateHour(Number(e.target.value))}
                    min="0"
                    max="23"
                    className="form-control"
                    placeholder="Hour (0-23)"
                />
                <input
                    type="number"
                    id="lateMinute"
                    value={lateMinute}
                    onChange={(e) => setLateMinute(Number(e.target.value))}
                    min="0"
                    max="59"
                    className="form-control mt-2"
                    placeholder="Minute (0-59)"
                />
            </div>

            {groupedTimecards.map(({ date, entries, daysAgo }) => (
                <div key={date} className="list-group mb-3">
                    <div className="list-group-item list-group-item-action" aria-current="true">
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">{date}</h5>
                            <small>{daysAgo} days ago</small>
                        </div>
                    </div>
                    {entries.map((card, index) => {
                        const applyRedStyle = index === 0 && card.type === 'IN' && isLate(card.timestamp);
                        return (
                            <div key={card.id} className="list-group-item d-flex justify-content-between align-items-center">
                                <p className="mb-1" style={{ color: applyRedStyle ? 'red' : 'black' }}>
                                    {card.type} at {card.formattedTime}
                                </p>
                                <button className="btn btn-danger btn-sm" onClick={() => handleDeleteClick(card.id)}>
                                    <i className="bi bi-trash"></i>
                                </button>
                            </div>
                        );
                    })}
                    <div className="list-group-item">
                        <p
                            className="mb-1 font-weight-bold"
                            style={{
                                color: calculateTotalTime(entries).totalMinutes < 480 ? 'red' : 'black'
                            }}
                        >
                            Total Time: {calculateTotalTime(entries).formatted}
                        </p>
                    </div>
                </div>
            ))}

            {/* Modal */}
            {showModal && (
                <div className="modal show" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirm Deletion</h5>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to delete this timecard entry?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                                    Cancel
                                </button>
                                <button type="button" className="btn btn-danger" onClick={confirmDelete}>
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AdminTimeCardPage;
