import React, { useState, useEffect } from 'react';
import { db } from './firebaseConfig';
import { collection, getDocs, writeBatch, doc } from 'firebase/firestore';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function DeleteAll() {
    const [documents, setDocuments] = useState([]);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const fetchDocuments = async () => {
            const querySnapshot = await getDocs(collection(db, 'attendance'));
            setDocuments(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        };
        fetchDocuments();
    }, []);

    const handleDelete = async () => {
        const batch = writeBatch(db);
        documents.forEach((document) => {
            batch.delete(doc(db, 'attendance', document.id));
        });

        try {
            await batch.commit();
            setDocuments([]); // Clear the documents state
            setShowModal(false); // Close modal
            alert('All records have been deleted.');
        } catch (error) {
            console.error('Error deleting all documents:', error);
            alert('Failed to delete all documents');
        }
    };

    return (
        <div className="container mt-3">
            <Button variant="danger" onClick={() => setShowModal(true)}>Delete All Records</Button>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete all records? This action cannot be undone.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                    <Button variant="danger" onClick={handleDelete}>Delete All</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default DeleteAll;
