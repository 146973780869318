import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer className="bg-light text-center text-lg-start">
            <div className="container p-4">
                <div className="row">
                    <div className="col-lg-6 col-md-12 mb-4 mb-md-0">
                        <ul className="list-unstyled mb-0">
                            <li>
                                <Link to="/contact-us" className="text-dark">Contact Us</Link>
                            </li>
                            <li>
                                <Link to="/terms-of-use" className="text-dark">Terms of Use</Link>
                            </li>
                            <li>
                                <Link to="/privacy-policy" className="text-dark">Privacy Policy</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="text-center p-3 bg-dark text-white">
            <h6 className="text-uppercase">Axial Progress</h6> <p>© 2024 - All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
